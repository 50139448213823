// ... your code that runs before foundation initiation

// Fix for foundation stylesheets being picked up as "null" or "not an object",
// implementation from here: http://foundation.zurb.com/forum/posts/3189-foundation-5-orbit-slider-ie8-issue
(function($) {
    'use strict';

    if (!Foundation.stylesheet) {
        Foundation._style_element = $('<style></style>').appendTo('head')[0];
        Foundation.stylesheet = Foundation._style_element.styleSheet;

        if (Foundation.stylesheet) {
            Foundation.stylesheet.cssRules = {
                length: 0
            };

            Foundation.stylesheet.insertRule = function(rule, index) {
                var media;
                var mediaMatch;
                var mediaRegex;
                var namespace;
                var ruleMatch;
                var ruleRegex;
                mediaRegex = /^\s*@media\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
                mediaMatch = mediaRegex.exec(rule);
                media = '';

                if (mediaMatch) {
                    media = '@media ' + mediaMatch[1] + ' ';
                    rule = mediaMatch[2];
                }

                ruleRegex = /^\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
                ruleMatch = ruleRegex.exec(rule);
                namespace = '' + media + ruleMatch[1];
                rule = ruleMatch[2];

                return this.addRule(namespace, rule);
            };
        } else if (window.console) {
            console.log('Could not fix Foundation CSS rules...');
        }
    }
})(jQuery);

// Accessible image sprites (modificat):
// https://www.paciellogroup.com/blog/2012/08/notes-on-accessible-css-image-sprites/

function HCTest() {
    'use strict';

    var objDiv;
    var strColor;
    var objFlag;

    // reference to img element used to check if images are disabled
    objFlag = document.getElementById('flag');
    if (objFlag === null) {
        return;
    }

    // Create a test div
    objDiv = document.createElement('div');

    // Set its color style to something unusual
    objDiv.style.color = 'rgb(31,41,59)';

    // Attach to body so we can inspect it
    document.body.appendChild(objDiv);

    // Use standard means if available, otherwise use the IE methods

    strColor = document.defaultView ? document.defaultView.getComputedStyle(objDiv, null).color : objDiv.currentStyle.color;

    // get rid of extra spaces in result
    // strColor = strColor.replace(/ /g,"");

    // Delete the test DIV

    document.body.removeChild(objDiv);

    // Check if we got back what we set (strColor== ??) If not we are in high contrast mode
    // Use .offsetwidth and .readyState (for IE) to check if images are enabled
    // If either images are disabled or high contrast is enabled (or both) the CSS stylesheet link will not be added to the page and the visually hidden text will be displayed in place of the missing background image

    if (!(((objFlag.offsetWidth === 1 && objFlag.readyState === 'complete') || (objFlag.offsetWidth === 1 && objFlag.readyState === undefined)) && (strColor === 'rgb(31,41,59)' || strColor === 'rgb(31, 41, 59)'))) {

        var objHead = document.getElementsByTagName('head');
        var objCSS = objHead[0].appendChild(document.createElement('link'));
        objCSS.rel = 'stylesheet';
        objCSS.href = '/media/css/alt.css';
        // objCSS.type = 'text/css';
    }
}

// Safely initiate foundation now
$(document).foundation();

// the rest of your code

$(window).load(function() {

    'use strict';

    // accessible image sprites
    HCTest();

});

$(document).ready(function() {

    'use strict';

    // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
    // bind a click event to the 'skip' link
    $(".skip-link").click(function(event){

        // strip the leading hash and declare
        // the content we're skipping to
        var skipTo="#"+this.href.split('#')[1];

        // Setting 'tabindex' to -1 takes an element out of normal
        // tab flow but allows it to be focused via javascript
        $(skipTo).attr('tabindex', -1).on('blur focusout', function () {

            // when focus leaves this element,
            // remove the tabindex attribute
            $(this).removeAttr('tabindex');

        }).focus(); // focus on the content container
    });

    // mobile-nav
    // crear botó menú mòbil
    $('.logo').after('<a href="#main-nav" class="js-mobile-menu" aria-label="Menú"><span class="vh">Menú</span></a>');
    // funcionament menú mòbil
    $('.js-mobile-menu').click(
        function() {
            if (!$('.main-nav').hasClass('is-visible')) {
                $('.main-nav')
                    .addClass('is-visible').
                    removeClass('is-hidden');
                // $('.main-nav').addClass('open');
                // $('.mobile-menu').addClass('close');
                $('body').addClass('js-menu-open');
                return false;
            } else {
                $('.main-nav')
                    .addClass('is-hidden')
                    .removeClass('is-visible');
                // $('.main-nav').removeClass('open');
                // $('.mobile-menu').removeClass('close');
                $('body').removeClass('js-menu-open');
                return false;
            }
        }
    );

    // contact
    // creació botó tancar
    $('.section-title--contact').before('<a class="js-close" href="#" aria-label="Tancar"></a>');

    // funcionament contacte
    $('.js-contact, .js-close').click(
        function() {
            if (!$('section.contact').hasClass('is-visible')) {
                $('section.contact')
                    .addClass('is-visible').
                    removeClass('is-hidden');
                // $('.main-nav').addClass('open');
                // $('.mobile-menu').addClass('close');
                $('body').addClass('js-contact-open');
                return false;
            } else {
                $('section.contact')
                    .addClass('is-hidden')
                    .removeClass('is-visible');
                // $('.main-nav').removeClass('open');
                // $('.mobile-menu').removeClass('close');
                $('body').removeClass('js-contact-open');
                return false;
            }
        }
    );
    $('.js-contact').click(
        function() {
            window.scrollTo(0, 0);
        }
    );
    var hash = window.location.hash;
    if (hash.length > 0) {
        if (hash == '#contact') {
            window.scrollTo(0, 0);
            $('section.contact')
                .addClass('is-visible').
                removeClass('is-hidden');
                // $('.main-nav').addClass('open');
                // $('.mobile-menu').addClass('close');
            $('body').addClass('js-contact-open');
        }
        else if (hash == '#form-ok') {
            window.scrollTo(0, 0);
            $('section.contact-ok')
                .addClass('is-visible').
                removeClass('is-hidden');
            $('body').addClass('js-contact-open');
            $('.section-title--contact-ok').before('<a class="js-close" href="#" aria-label="Tancar"></a>');
            $('.js-contact, .js-close').click(
                function() {
                    if (!$('section.contact-ok').hasClass('is-visible')) {
                        $('section.contact-ok')
                            .addClass('is-visible').
                            removeClass('is-hidden');
                        $('body').addClass('js-contact-open');
                        return false;
                    } else {
                        $('section.contact-ok')
                            .addClass('is-hidden')
                            .removeClass('is-visible');
                        $('body').removeClass('js-contact-open');
                        return false;
                    }
                }
            );
        }
        else if (hash == '#form-ko') {
            window.scrollTo(0, 0);
            $('section.contact-ko')
                .addClass('is-visible').
                removeClass('is-hidden');
                // $('.main-nav').addClass('open');
                // $('.mobile-menu').addClass('close');
            $('body').addClass('js-contact-open');
            $('.section-title--contact-ko').before('<a class="js-close" href="#" aria-label="Tancar"></a>');
            $('.js-contact, .js-close').click(
                function() {
                    if (!$('section.contact-ko').hasClass('is-visible')) {
                        $('section.contact-ko')
                            .addClass('is-visible').
                            removeClass('is-hidden');
                        $('body').addClass('js-contact-open');
                        return false;
                    } else {
                        $('section.contact-ko')
                            .addClass('is-hidden')
                            .removeClass('is-visible');
                        $('body').removeClass('js-contact-open');
                        return false;
                    }
                }
            );
        }
        else if (hash == '#form-ok-es') {
            window.scrollTo(0, 0);
            $('section.contact-ok')
                .addClass('is-visible').
                removeClass('is-hidden');
            $('body').addClass('js-contact-open');
            $('.section-title--contact-ok').before('<a class="js-close" href="#" aria-label="Cerrar"></a>');
            $('.js-contact, .js-close').click(
                function() {
                    if (!$('section.contact-ok').hasClass('is-visible')) {
                        $('section.contact-ok')
                            .addClass('is-visible').
                            removeClass('is-hidden');
                        $('body').addClass('js-contact-open');
                        return false;
                    } else {
                        $('section.contact-ok')
                            .addClass('is-hidden')
                            .removeClass('is-visible');
                        $('body').removeClass('js-contact-open');
                        return false;
                    }
                }
            );
        }
        else if (hash == '#form-ko-es') {
            window.scrollTo(0, 0);
            $('section.contact-ko')
                .addClass('is-visible').
                removeClass('is-hidden');
                // $('.main-nav').addClass('open');
                // $('.mobile-menu').addClass('close');
            $('body').addClass('js-contact-open');
            $('.section-title--contact-ko').before('<a class="js-close" href="#" aria-label="Cerrar"></a>');
            $('.js-contact, .js-close').click(
                function() {
                    if (!$('section.contact-ko').hasClass('is-visible')) {
                        $('section.contact-ko')
                            .addClass('is-visible').
                            removeClass('is-hidden');
                        $('body').addClass('js-contact-open');
                        return false;
                    } else {
                        $('section.contact-ko')
                            .addClass('is-hidden')
                            .removeClass('is-visible');
                        $('body').removeClass('js-contact-open');
                        return false;
                    }
                }
            );
        }
    }

    // contact 2 - (s'obre al final de cada apartat)
    $('.js-contact-2').click(
        function() {
            if (!$('.contact-2').hasClass('is-visible')) {
                $('.contact-2')
                    .addClass('is-visible').
                    removeClass('is-hidden');
                return false;
            } else {
                $('.contact-2')
                    .addClass('is-hidden')
                    .removeClass('is-visible');
                return false;
            }
        }
    );

    // mes feines
    // $('.mes-feines-btn').click(
    //     function() {
    //         if (!$('.mes-feines').hasClass('is-visible')) {
    //             $('.mes-feines')
    //                 .addClass('is-visible').
    //                 removeClass('is-hidden');
    //             return false;
    //         } else {
    //             $('.mes-feines')
    //                 .addClass('is-hidden')
    //                 .removeClass('is-visible');
    //             return false;
    //         }
    //     }
    // );

    // canvi svg per png
    // if (!feature.svg) {
    //     var imgs = document.getElementsByTagName('img');
    //     var endsWithDotSvg = /.*\.svg$/;
    //     var i = 0;
    //     var l = imgs.length;
    //     for (; i !== l; ++i) {
    //         if (imgs[i].src.match(endsWithDotSvg)) {
    //             imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
    //         }
    //     }
    // }

    // smooth scroll top
    $(window).scroll(function() {
        if ($(this).scrollTop() > 800) {
            $(".top").fadeIn()
        } else {
            $(".top").fadeOut()
        }
    });
    $(function() {
      $('a[href*="#"]:not([href="#"], .js-mobile-menu, .js-contact, .main-nav__item a)').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000);
            return false;
          }
        }
      });
    });

    // matchHeight
    $(function() {
        $('.work__link').matchHeight();
        $('.utils-intranet__item').matchHeight();
        $('.utils-infografies__item').matchHeight();
    });

    // scroll
    var lastScrollTop = 0;
    $(window).scroll(function(event){
       var st = $(this).scrollTop();
       if (st > lastScrollTop){
            $('body').addClass('js-out');
       } else {
            $('body').removeClass('js-out');
       }
       lastScrollTop = st;
    });

    // Thumbnail grid ('Som antaviana') - https://github.com/codrops/ThumbnailGridExpandingPreview
    /*$(function() {
        Grid.init();
    });*/

    //scrollreveal
     window.sr = ScrollReveal();
        // Add class to <html> if ScrollReveal is supported
        if (sr.isSupported()) {
            document.documentElement.classList.add('sr');
        }

    // scrollreveal
    // Ensure elements load hidden before ScrollReveal runs: .sr .fooReveal { visibility: hidden; }
    sr.reveal('.team__member, .work__item, .logos-list__item, .utils-intranet__item, .altres-feines__item, .pict');
    // sr.reveal('.case__bg', { scale: 1, duration: 1000 });



    // slick slider
    $('.slider').slick({
        dots: true
    });

    // Magnific pop-up form sol·licitud Canal Denúncies
    $('.popup-solicitud').magnificPopup({
		type: 'inline',
		preloader: false,
		focus: '#nom',

		// When elemened is focused, some mobile browsers in some cases zoom in
		// It looks not nice, so we disable it:
		callbacks: {
			beforeOpen: function() {
				if($(window).width() < 700) {
					this.st.focus = false;
				} else {
					this.st.focus = '#nom';
				}
			}
		}
	});

    // -------------
    // Animació SVGs
    // -------------
    $(".denuncies-grid svg").each(function () {
        var element = this;
        $(element).addClass("hidden");
        $(this).viewportChecker({
        classToAdd: "visible",
        callbackFunction: function () {
            new Vivus(element, {
            duration: 100,
            animTimingFunction: Vivus.EASE_OUT,
            });
        },
        });
    });

});
/***************************
* Lazy-load de imágenes con el desplazamiento. (2)
* Autor del Js: Furoya
* Post: https://escss.blogspot.com/2015/06/javascript-con-furoya-parallax-y-lazy.html
***************************/

var cadaNoscript, altoViewPort, desplazado, posicionCambio;


function mueve() {
cadaNoscript = document.querySelectorAll("figure noscript");

altoViewPort = window.innerHeight;

desplazado = self.pageYOffset || document.documentElement.scrollTop;

posicionCambio = desplazado + altoViewPort - 100;

  for(ns=0; ns<cadaNoscript.length; ns++){
    if(cadaNoscript[ns].parentElement.offsetTop < posicionCambio){
    var codigo = cadaNoscript[ns].innerHTML;

    codigo = codigo.replace(/&lt;/gi, "<").replace(/&gt;/gi, ">");

    cadaNoscript[ns].outerHTML = codigo;
    }
  }
}

onscroll = mueve;

/*var svgContainer = document.getElementById('video_0');
var animItem = bodymovin.loadAnimation({
  wrapper: svgContainer,
  animType: 'svg',
  loop: true,
  path: '/media/ico-animate/data.json'
});*/
