$('.contact-form').each(function() {//per cada formulari
    'use strict';
    //validacio formulari
    $(this).validate({
        errorPlacement: function(error, element) {
            if (element.attr('type') === 'checkbox' || element.attr('type') === 'radio') {
                if (element.closest('div').find('input').length > 1) {
                    error.insertAfter(element.closest('div').find('p.type-label'));
                } else {
                    error.insertAfter(element.parent('label'));
                }
            } else {
                error.insertAfter(element);
            }
        }
    });
});
$('#houdiniControl').remove();