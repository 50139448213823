$(document).ready(function () {
    ("use strict");

    // -----------
    // Animate.css
    // -----------
    $(".anim-fi").addClass("hidden").viewportChecker({
      classToAdd: "visible animate__animated animate__fadeIn",
      offset: 100,
    });
    $(".anim-fi-right").addClass("hidden").viewportChecker({
      classToAdd: "visible animate__animated animate__fadeInRight",
      offset: 100,
    });
    $(".anim-fi-left").addClass("hidden").viewportChecker({
      classToAdd: "visible animate__animated animate__fadeInLeft",
      offset: 100,
    });
    $(".anim-fi-up").addClass("hidden").viewportChecker({
      classToAdd: "visible animate__animated animate__fadeInUp",
      offset: 100,
    });
    $(".anim-fi-down").addClass("hidden").viewportChecker({
      classToAdd: "visible animate__animated animate__fadeInDown",
      offset: 100,
    });
    $(".anim-flip").addClass("hidden").viewportChecker({
      classToAdd: "visible animate__animated animate__flipInX",
      offset: 100,
    });
  });
